
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  background-color: #eeddd3;

}
.fullPage {
  padding: 50px;
}
.emoji-line {
  font-size: 12px;
}
.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.socials { margin-top: 20px;}
.socials img {
  width: 50px;
}
.about-img {
  height: 100%;
  width: 200px;
  padding: 10px;
}
.about-img img {
  border-radius: 50% 50%;
  width: 100%;
}
.image-container img {
  width: 100%;
  height: auto;
}
.counter {
  background-image: url("./assets/Botw.jpg");
  background-size: cover;
  background-position: center;
  height: 400px;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  color: #fff;
  z-index: 0;
}
.counter .btns {
  margin-top: 40px;
}
#increment-btn {
  background-color: green;
  color: white;
}
#decrease-btn {
  background-color: darkorange;
  color: white;
}
#clear-btn {
  background-color: darkred;
  color: white;
}
.counter .overlay {
  background-color: rgb(0 0 0 / 60%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

footer {
  display: flex;
  justify-content: center;
  bottom: 0;
  position: absolute;
  left: 0;
  width: 100%;
}

/*Margins and paddings*/
.mt-50 { margin-top: 50px; }

/*Media queries*/
@media (max-width: 900px) {
  .fullPage {
    padding: 20px;
  }
}